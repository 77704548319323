.textPageParentDiv {
    width: 75%;
    height: 75%;
  
    position:absolute; 
    left:0; right:0;
    top:0; bottom:0;
    margin:auto;
  
    background-color: #1a1a1a;
    border: 5px solid #aaaaaa;
    border-radius: 5px;
  
    color: #ffffff;
    font-family: 'Roboto Slab';
  }
  
  .textPageTextElementsDiv {  
    position: absolute;
    margin: auto;
    top: 10%;
    left: 0; right: 0;
    width: 87.8%; height: 90%;
  
    font-size: 20px;
  }
  
  .textPageTitle {
    position: absolute; 
    left: 6%;
    font-size: 50px;
  }
  
  .textPageTitleLine {
    width: 90%;
    height: 4px;
  
    background-color: #aaaaaa;
    border: 5px round #aaaaaa;
    border-radius: 4px;
  
    position: absolute; 
    left: 0; right: 0;
    top: 10%;
    margin: auto;
  }

  .textPageBackButton:hover {
    color: indigo;
  }

  .textPageBackButton {
    width: 50px;
    height: 10px;
    border: 5px round #aaaaaa;
    border-radius: 4px;
    
    z-index: 10;  
    position: absolute;  
    right: 5%;  
    top: 4%;
  }